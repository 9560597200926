/* latin-ext */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Caveat.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Caveat.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
* {
  box-sizing: border-box;
  padding: 0;
}
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.1);
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
}
body {
  font-family: 'Open Sans', sans-serif;
  background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px, linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0, linear-gradient(27deg, #222 5px, transparent 5px) 0 10px, linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px, linear-gradient(90deg, #1b1b1b 10px, transparent 10px), linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
  background-color: #131313;
  background-size: 20px 20px;
  background-attachment: fixed;
}
body::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 30px;
  content: '';
  background-color: #222;
}
.body {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 670px;
}
a {
  color: #ccc;
  text-decoration: none;
  transition: all 0.5s;
}
a:hover {
  color: #ff9601;
  text-decoration: none;
  cursor: pointer;
}
h1,
h2,
h3,
.logo-subtitle {
  font-family: 'Caveat', cursive;
}
header .logo h1 {
  margin: 10px 20px 10px;
  padding: 8px 26px;
  font-size: 52px;
  letter-spacing: 2px;
  transform: rotate(10deg);
}
header .logo-subtitle {
  margin: 5px 0 5px 25px;
  padding: 4px 18px;
  font-size: 32px;
  letter-spacing: 1px;
  transform: rotate(-2deg);
}
header .logo h1,
header .logo-subtitle {
  background: radial-gradient(grey 15%, transparent 16%) 0 0, radial-gradient(grey 15%, transparent 16%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #ff9601;
  background-size: 16px 16px;
  display: inline-block;
  font-weight: 700;
  color: #000;
  transition: all 0.5s;
}
header .logo h1:hover,
header .logo-subtitle:hover {
  color: #fff;
  text-shadow: 2px 2px 2px #000;
}
main {
  border-radius: 5px;
  margin: 15px auto;
  padding: 15px 20px;
  color: #fff;
  background-color: rgba(20, 20, 20, 0.9);
  box-shadow: 0 0 20px rgba(20, 20, 20, 0.8);
}
main hr {
  display: block;
  clear: both;
  margin: 1.5% 0;
  border: 0;
  height: 1px;
  color: #333;
  background-color: #444;
}
main article {
  content-visibility: auto;
  /* stylelint-disable-line */
  contain-intristic-size: 1px 500px;
  /* stylelint-disable-line */
}
main article h2 {
  padding: 5px 0;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #fff;
  text-shadow: 1px 1px 4px #ff9601;
  transition: all 0.5s;
}
main article h2:hover {
  color: #ff9601;
  text-shadow: 2px 2px 2px #fff;
}
main article iframe {
  border: none;
  width: 100%;
}
main article .content {
  display: block;
  padding: 0 1%;
}
main article .content ol,
main article .content ul {
  margin: 0 0 0 4%;
}
main article .content p {
  padding: 1% 0;
  font-size: 16px;
  line-height: 24px;
}
main article .content p::first-letter {
  margin-left: 4%;
}
main article .content p a {
  border-bottom: 1px dashed rgba(200, 200, 200, 0.3);
}
main article .content img {
  clear: both;
  margin: 10px;
  padding: 2px;
  width: 95%;
  min-width: 200px;
  min-height: 100px;
  background-color: #fff;
  box-shadow: 0 0 25px #ff9601;
  transition: all 0.5s;
}
main article .content img:hover {
  background-color: #ff9601;
  box-shadow: 0 0 25px #fff;
}
main article time {
  display: block;
  margin: 5px;
  font-size: 11px;
  text-align: right;
}
main ul.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
}
main ul.pagination li a {
  padding: 3px 4px;
}
main ul.pagination li a[aria-current] {
  color: #ff9601;
}
main ul.pagination li::after {
  content: '|';
}
main ul.pagination li:last-child::after {
  content: '';
}
footer {
  position: relative;
  padding: 10px 5px;
  transform: rotate(3deg);
}
footer .background {
  background: radial-gradient(grey 15%, transparent 16%) 0 0, radial-gradient(grey 15%, transparent 16%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #ff9601;
  background-size: 16px 16px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -5;
  opacity: 0.5;
}
footer nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style: none;
}
footer nav ul li a {
  display: block;
  position: relative;
  padding: 2px;
  color: #fff;
  text-shadow: 0 0 2px #000;
}
footer nav ul li a::before {
  position: absolute;
  left: -2px;
  top: 20%;
  width: 2px;
  height: 50%;
  content: '';
  background-color: #ff9601;
}
footer .bio {
  display: flex;
  padding: 10px;
  color: #fff;
  text-shadow: 0 0 2px #000;
}
footer .bio-icon {
  border-radius: 50%;
  width: 150px;
  height: 150px;
}
footer .bio p {
  padding: 20px 10px;
}
.floatBar {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  padding: 5px 10px;
  font-size: 11px;
  color: #ff9601;
  white-space: nowrap;
  background-color: #222;
  box-shadow: 0 0 25px #000;
}
.floatBar .sideslide {
  display: inline-block;
  cursor: pointer;
}
.floatBar .sideslide-content {
  position: fixed;
  right: -500px;
  top: 0;
  padding: 15px;
  height: 100%;
  overflow: auto;
  font-size: 12px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.5s right;
}
.floatBar .sideslide-content h3 {
  font-size: 28px;
  font-weight: 400;
}
.floatBar .sideslide-content ul {
  padding: 0;
  list-style: none;
}
.floatBar .sideslide-content ul li {
  margin: 8px;
}
.floatBar .sideslide:hover .sideslide-content {
  right: 0;
}
